import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>A Visionary Leader in AI-Driven Industry Disruption</h2>
          <p>Our AI solutions go beyond automation—they elevate businesses to new levels of operational excellence by providing actionable intelligence, not just data. When the stakes are high and expectations higher, we are the partner you trust.</p>
        </div>
        <div className="text-center">
      <h3>Testimonials</h3>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
